<!--
 * @Author: kevinzgai zhanggai_ok@126.com
 * @Date: 2023-11-27 14:37:26
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2023-12-04 19:31:04
 * @FilePath: \zyt-zlb-mobile-develop\src\views\cinemaAssistant\competitorCinema\sessionDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div>
      <van-cell-group>
        <van-field
          label="当前影院: "
          :custom-style="{ color: 'red' }"
          style="color: aliceblue"
          :value="ourCinema.cinemaName"
          readonly
        />
        <!-- 选中竞对影院 -->
        <van-field
          label="竞对影院: "
          placeholder="请选择竞对影院"
          custom-style="color: aliceblue;"
          clickable
          readonly
          right-icon="arrow-down"
          :value="selectedCompetitorCinema.cinemaName"
          @click-input="handleCompetitorCinemaClick"
        >
        </van-field>
      </van-cell-group>
    </div>
    <div style="height: 50px">
      <van-tabs
        color="#1989fa"
        v-model="activeTabIndex"
        title-active-color="#1989fa"
        @click="handleTabClick"
        v-show="showTabs"
      >
        <van-tab title="< 前一天" :name="0"> </van-tab>
        <van-tab :title="activeDate" :name="1"> </van-tab>
        <van-tab title="后一天 >" :name="2"> </van-tab>
      </van-tabs>
    </div>

    <div>
      <ve-table
        :scroll-width="0"
        :table-data="filmData"
        :max-height="maxHeight"
        :fixed-header="true"
        :columns="columns"
        :border-y="true"
        id="tabs"
        v-if="filmData.length > 0"
      ></ve-table>
      <div v-else style="text-align: center; margin-top: 20px">
        <van-empty description="暂无数据" />
        <div style="margin-top: 20px">
          <van-button block> 刷新 </van-button>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showCompetitorCinema"
      round
      position="center"
      :style="{ width: '90%', height: '50%' }"
      @click-overlay="onClose"
    >
      <van-picker
        show-toolbar
        title="请选择竞对影院"
        value-key="cinemaName"
        :columns="competitorCinemaList"
        @cancel="onClose"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
export default {
  data() {
    return {
      activeDate: '2023-11-28',
      activeTabIndex: 1,
      showTabs: true,
      cinemaName: '',
      // 表格最大高度
      maxHeight: 900,
      filmData: [],
      // 我方影院
      ourCinema: {},
      // 竞对方影院
      competitorCinema: {},
      columns: [
        {
          title: '序号',
          fixed: 'left',
          field: 'index',
          key: 'index',
          width: 40,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <div>
                  <span>{rowIndex + 1}</span>
                </div>
              </div>
            )
          },
        },
        {
          title: '影片名称',
          fixed: 'left',
          field: 'filmName',
          key: 'filmName',
          width: 120,
        },
        {
          title: '场次数',
          field: 'sessionNum',
          key: 'sessionNum',
          width: 90,
          children: [
            {
              title: '当前影院',
              field: 'sessionNum',
              key: 'sessionNum-our',
              width: 90,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.sessionNum.our.toFixed(0)}场</span>
                    </div>
                  </div>
                )
              },
            },
            {
              title: '竞对影院',
              field: 'sessionNum',
              key: 'sessionNum-competitor',
              width: 90,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.sessionNum.competitor.toFixed(0)}场</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
        {
          title: '场次数排名',
          field: 'sessionRank',
          key: 'sessionRank',
          width: 90,
          children: [
            {
              title: '当前影院',
              field: 'sessionRank',
              key: 'sessionRank-our',
              width: 90,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.sessionRank.our.toFixed(0)}场</span>
                    </div>
                  </div>
                )
              },
            },
            {
              title: '竞对影院',
              field: 'sessionRank',
              key: 'sessionRank-competitor',
              width: 90,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.sessionRank.competitor.toFixed(0)}场</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
      ],
      // 竞对影院列表
      competitorCinemaList: [],
      // 选中的竞对影院
      selectedCompetitorCinema: {},
      // 显示竞对影院列表
      showCompetitorCinema: false,
    }
  },
  mounted() {
    // 动态计算表格最大高度
    this.maxHeight = window.innerHeight - 100
    //默认选中前一天日期
    this.activeDate = this.moment().subtract(1, 'days').format('YYYY-MM-DD')
    this.getCompetitorCinema().then((res) => {
      this.competitorCinemaList = res
      const competitorCinemaId = this.$route.query.id
        ? parseInt(this.$route.query.id)
        : null
      console.log({ competitorCinemaId })
      console.log(res.find((item) => item.id === competitorCinemaId))
      // this.selectedCompetitorCinema = res.find(item=> item.id === parseInt(competitorCinemaId))
      this.ourCinema = JSON.parse(this.$route.query.ourCinema)
      this.getCompetitorCinemaSessionDetail()
    })
  },
  methods: {
    // 获取竞对影院
    getCompetitorCinema() {
      console.log(this.$route.query)
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.$route.query.id,
      }
      console.log('获取竞对影院')
      return new Promise((resolve, reject) => {
        zjApi
          .getCompetitorList(query)
          .then((res) => {
            console.log({ res })
            this.competitorCinemaList = res
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * @description: 选中竞对影院
     * @return {*}
     */
    handleCompetitorCinemaClick() {
      console.log('选中竞对影院')
      this.showCompetitorCinema = true
      console.log(
        { showCompetitorCinema: this.showCompetitorCinema },
        '执行竞对查询',
      )
    },
    // 获取竞对影院场次详情
    getCompetitorCinemaSessionDetail() {
      // UserCode=1&ourCinemaId=33010201&competitorCinemaId=33017941&date=2023-11-28
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.ourCinema.id,
        competitorCinemaId: this.$route.query.id,
        date: this.activeDate,
      }
      zjApi.getAnalysisSessionInfo(query).then((res) => {
        this.filmData = res
        console.log({ res })
      })
    },
    // 加载前一天
    loadPreviousDay() {
      console.log('加载前一天')
      setTimeout(() => {
        this.activeDate = this.moment(this.activeDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
        this.activeTabIndex = 1
        this.showTabs = true
      }, 10)
    },
    // 加载后一天
    loadNextDay() {
      console.log('加载后一天')
      setTimeout(() => {
        this.activeDate = this.moment(this.activeDate)
          .add(1, 'days')
          .format('YYYY-MM-DD')
        this.activeTabIndex = 1
        this.showTabs = true
      }, 10)
    },
    // 关闭竞对影院列表
    onClose() {
      this.showCompetitorCinema = false
    },

    /**
     * @description: 确认选中竞对影院
     * @param {*} value 选中的竞对影院
     * @param {*} index 选中的竞对影院索引
     * @return {*}
     */
    onConfirm(value, index) {
      console.log({ value, index })
      this.selectedCompetitorCinema = value
      this.showCompetitorCinema = false
      this.getCompetitorCinemaSessionDetail()
    },
    /**
     * @description: 切换tab
     * @param {*} tab
     * @return {*}
     */
    handleTabClick(tab) {
      const { index } = tab
      const _this = this
      _this.showTabs = false
      console.log({ tab })
      switch (tab) {
        case 0:
          _this.loadPreviousDay()
          break
        case 1:
          _this.showTabs = true
          console.log('当前日期')
          break
        case 2:
          _this.loadNextDay()
          break
        default:
          break
      }
    },
  },
  watch: {
    activeDate(val) {
      console.log({ val })
      this.getCompetitorCinemaSessionDetail()
    },
  },
}
</script>
<style scoped lang="less">
.my-cell {
  color: red;
}
</style>
